<template>
	<div class="flex-column full-height bg-gray-light">
		<div class=" bg-white mb-10">

			<div
				class=" items-center"
			>
				<div
					class="justify-space-between items-center pa-10"
				>
					<DatePicker :date="item_search.sDate" :is_move="1" @click="setDate('sDate', $event)" class="inline-block ml-10"></DatePicker>
					~
					<DatePicker :date="item_search.eDate" :is_move="1" @click="setDate('eDate', $event)" class="inline-block mr-10"></DatePicker>
				</div>

				<div class="justify-space-between items-center ">
					<input v-model="item_search.keyword" class="box pa-5-10 flex-1" placeholder="검색어" @keyup.enter="getSearch(1)"/>
					<button
						class="btn-inline btn-default"

						@click="reset"
					><v-icon>mdi-refresh</v-icon></button>
					<button
						class="btn-inline btn-success"

						@click="getSearch(1)"
					>검색</button>
				</div>
			</div>
		</div>

		<div class="full-height overflow-y-auto ">
			<div
				v-if="items.length > 0"
			>
				<ul
				>
					<li
						v-for="(item, index) in list_history"
						:key="'item_' + index"
						class="under-line pa-10 justify-space-between-in-box mb-10 bg-white"
					>
						<div class=" under-line-dashed pb-10">
							<span>{{ item.wDate}}</span>
							<span :class="item.status_color">{{ item.status_name }}</span>
						</div>
						<div class="mt-10 ">
							<span :class="item.type_color">
								{{ item.send_type_name }}
							</span>
							<span :class="item.type_color" class="font-weight-bold" >

								<span>{{ item.type_name}}</span> {{ item.amount | makeComma}}원
							</span>
						</div>
						<div class="mt-10 text-right  under-line-dashed pb-10">
							<span>{{ item.bank_depositor }}</span>
						</div>
						<div class="mt-10 text-right flex-1">
							<span>{{ item.bank_name }} {{ item.bank_account }} {{ item.bank_holder }}</span>
						</div>
					</li>
				</ul>
				<Pagination
					:program="program"
					:align="'center'"
					:options="item_search"

					@click="getSearch"

					class="mt-auto pa-10"
				></Pagination>
			</div>
			<Empty
				v-else
			></Empty>
		</div>
	</div>
</template>

<script>
import Empty from "../Layout/Empty";
import Pagination from "../../components/Pagination";
import DatePicker from "../../components/DatePicker";
export default {
	name: 'VirtualHistory'
	, components: {DatePicker, Pagination, Empty}
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: '가상계좌 내역'
				, top: false
				, title: true
				, bottom: true
			}
			, items: []
			, item_search: {
				page: 1
				, list_cnt: 10
				, total_count: 0
				, cnt: 0
				, keyword: ''
				, sDate: this.$date.getSearchBaseDate('-')
				, eDate: this.$date.getToday('-')
			}
		}
	}
	, computed: {
		list_history: function(){
			return this.items.filter( (item) => {
				if(item.history_type == 0){
					item.type_name = '출금'
					item.type_color = 'color-red'
				}else{
					item.type_name = '입금'
					item.type_color = 'color-blue'
				}
				if(item.status == 1){
					item.status_name = '완료'
					item.status_color = 'color-green'
				}else{
					item.status_name = '-'
				}

				switch (item.send_type){
					case '0':
						item.send_type_name = '내부 송금'
						break;
					case '1':
						item.send_type_name = '가상계좌'
						break;
					case '3':
						item.send_type_name = '정산'
						break;
					case '5':
						item.send_type_name = '수수료'
						break;
					case '7':
						item.send_type_name = '정산 차감'

						item.type_name = '미지급'
						item.type_color = 'color-orange'
						break;
				}

				this.user.bank_list?.filter( (bank) => {
					if(item.bank_code == '0'){
						item.bank_name = '내부 송금'
					}else{
						if(bank.bankId == item.bank_code){
							item.bank_name = bank.bankName
						}
					}
				})
				return item
			})
		}
	}
	, methods: {
		getData: async function(){
			this.$bus.$emit('on', true)
			try{
				const result = await this.$Axios({
					method: 'get'
					, url: this.$api_url.api_path.get_merchant_history
					,data: this.item_search
				})

				if(result.success){
					this.items = result.data.result
					this.item_search.total_count = result.data.tCnt
					this.item_search.cnt = result.data.cnt
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getSearch: function(page = 1){
			if(page){
				this.item_search.page = page
			}
			this.getData()
		}
		, setDate: function(type, e){
			this.item_search[type] = e
		}
		, reset: function(){
			this.item_search = {
				page: 1
				, list_cnt: 10
				, total_count: 0
				, cnt: 0
				, keyword: ''
				, sDate: this.$date.getSearchBaseDate('-')
				, eDate: this.$date.getToday('-')
			}
			this.getData()
		}
	}
	, created() {
		this.$bus.$emit('onLoad', this.program)
		this.getData()
	}
}
</script>